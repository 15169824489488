@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './animation';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #172554;
  background-color: #C51730;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.signature {
  font-family: "Nothing You Could Do", cursive, sans-serif;
}

#container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.primary-logo {
  height: 70vmin;
  pointer-events: none;
  border: 5px dotted #C51730;
  border-radius: 50%;
}

.max-h-content {
  height: max-content
}

.rotate-img {
  rotate: 12deg;
}